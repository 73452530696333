<template>
  <b-sidebar
    id="pickuptime-form"
    ref="sidebar"
    width="50%"
    bg-variant="white"
    right
    backdrop
    no-header
    @hidden="resetData()"
    @shown="getPickuptimeData()"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ isObjectEmpty(pickuptimeInfo) ? 'Edit Pickup Time': 'Add Pickup Time' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- form -->
      <validation-observer ref="pickup-time-form">
        <b-form
          class="p-1"
          @submit.prevent
        >
          <b-row>
            <!-- Text -->
            <b-col
              v-if="!tourId"
              md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="tour"
                rules="required"
              >
                <b-form-group
                  label="Tour"
                  label-for="tour"
                >
                  <v-select
                    id="tour"
                    v-model="pickuptimeDetail.tour"
                    :options="tourList"
                    :clearable="false"
                    label="name"
                    placeholder="Search tour..."
                    @input="(val) => currencyCode = val.currency_code"
                    @search:focus.once="getTourList"
                    @search="(search) => debounceSearch(0, () => getTourList(search))"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="text"
                rules="required"
              >
                <b-form-group
                  label="Text"
                  label-for="Text"
                >
                  <b-form-input
                    id="Text"
                    v-model="pickuptimeDetail.text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Price -->
            <b-col md="6">
              <b-form-group
                label="Price"
                label-for="Price"
              >
                <b-input-group :prepend="`${currencyCode || 'BDT'}`">
                  <b-form-input
                    id="Price"
                    v-model="pickuptimeDetail.price"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Time -->
            <b-col md="6">

              <validation-provider
                #default="{ errors }"
                name="time"
                rules="required"
              >
                <b-form-group
                  label="Time"
                  label-for="Time"
                >
                  <b-input-group>
                    <cleave
                      id="time"
                      v-model="pickuptimeDetail.time"
                      class="form-control"
                      :raw="false"
                      :options="options.time"
                      placeholder="hh:mm"
                    />
                    <b-input-group-append>
                      <b-form-timepicker
                        id="time"
                        v-model="pickuptimeDetail.time"
                        button-variant="outline-primary"
                        locale="en"
                        size="sm"
                        button-only
                        right
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger mb-1 d-block">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- Submit -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isBtnLoading"
                @click="validateForm"
              >
                <b-spinner
                  v-if="isBtnLoading"
                  small
                />
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import Cleave from 'vue-cleave-component'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'PickupForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  mixins: [helper],
  props: {
    pickuptimeInfo: {
      type: Object,
      default: () => ({}),
    },
    tourId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isBtnLoading: false,
      pickuptimeDetail: {},
      currencyCode: this.$store.state.tour.property.currency_code || 'BDT',
      tourList: [],
      required,
      options: {
        time: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
    }
  },
  methods: {
    createOrUpdatePickuptime() {
      this.isBtnLoading = true
      let url = 'tour/admin_services/pickup_time_create/'
      let apiResponse
      const pickuptimeExists = this.pickuptimeDetail.id
      const formData = JSON.parse(JSON.stringify(this.pickuptimeDetail))
      formData.currency_code = this.currencyCode
      formData.tour = this.tourId || this.pickuptimeDetail.tour.id
      if (pickuptimeExists) {
        url = `tour/admin_services/pickup_time/${this.pickuptimeInfo.id}/update/`
        apiResponse = api.updateData(url, true, formData)
      } else {
        apiResponse = api.postData(url, true, formData)
      }
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', `pickup time ${pickuptimeExists ? 'updated' : 'created'} successfully`)
          this.$emit('getPickuptimeDetails')
          this.$emit('addPickup', response.data.result)
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    resetData() {
      this.$emit('resetData')
    },
    getPickuptimeData() {
      this.pickuptimeDetail = JSON.parse(JSON.stringify(this.pickuptimeInfo))
    },
    getTourList(search) {
      const query = search ? `&search=${search}` : ''
      api.getData(`tour/admin_services/tour_list/?offset=0${query}`, true)
        .then(response => {
          if (response.data.status) {
            this.tourList = response.data.result.results
          }
        }).catch()
    },
    validateForm() {
      this.$refs['pickup-time-form'].validate().then(success => {
        if (success) {
          this.createOrUpdatePickuptime()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
