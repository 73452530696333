import { render, staticRenderFns } from "./PickupForm.vue?vue&type=template&id=296df373&scoped=true&"
import script from "./PickupForm.vue?vue&type=script&lang=js&"
export * from "./PickupForm.vue?vue&type=script&lang=js&"
import style0 from "./PickupForm.vue?vue&type=style&index=0&id=296df373&lang=scss&scoped=true&"
import style1 from "./PickupForm.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296df373",
  null
  
)

export default component.exports